import HTTP from '@api';

import { generateXHRError } from '@helpers';

export const AccessJournalApiService = {
  async getAccessJournalRecords({
    loginUserName = null, category = null, dates = null,
    isError = null, orderId = null,
    pageNum = 1, pageSize = 10,
  }) {
    try {
      const { data } = await HTTP.get('login/access-journal', {
        params: {
          loginUserName,
          category,
          orderId,
          pageNum,
          pageSize,
          isError,
          startDate: dates && dates.length ? dates[0] : null,
          endDate: dates && dates.length ? `${dates[1]} 23:59:59` : null,
        },
      });

      return data.items || [];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async getAccessJournalCategories() {
    try {
      const { data } = await HTTP.get('login/access-journal/categories');

      return data || [];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};

export default {};

<template>
  <v-layout column>
    <vbt-search
      v-model="searchData"
      :items="searchFieldsConfig"
      @reset="onResetSearch"
      @search="onSearch"
    />

    <vbt-content-box
      :loading="isLoading"
      :loading-error="loadingError"
      :no-content="noContent"
      title="Information"
    >
      <vbt-table
        :headers="accessJournalRecordsHeaders"
        :items="accessJournalRecords"
      >
        <template #orderId="{ item: { orderId } }">
          {{ orderId || '-' }}
        </template>

        <template #timestamp="{ item: { timestamp } }">
          {{ formatDisplayDate(timestamp) }}
        </template>

        <template #isError="{ item: { isError } }">
          <ir-state-icon :state="isError" />
        </template>

        <template #arguments="{ item }">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                :disabled="!item.arguments"
                icon
                small
                color="info"
                v-on="on"
                @click="showArguments(item.arguments)"
              >
                <v-icon>
                  remove_red_eye
                </v-icon>
              </v-btn>
            </template>

            Show arguments
          </v-tooltip>
        </template>
      </vbt-table>

      <vbt-pagination
        v-model="pageNum"
        :items-count="pagedItemsCount"
      />

      <vbt-dialog
        v-model="argumentsCodeDialog"
        title="Arguments"
        hide-submit-btn
        width="900px"
      >
        <vbt-code
          :value="argumentsCode"
          readonly
        />
      </vbt-dialog>
    </vbt-content-box>
  </v-layout>
</template>

<script>
import { formatDisplayDate, wrapToLoadingFn } from '@helpers';

import { usePagination, useSearch, useDataLoading } from '@mixins/factories';

import { AccessJournalApiService } from '../_services/accessJournal.api.service';

const dataLoadingOptions = { getterName: 'getAccessJournalRecords' };
const paginationOptions = { itemsName: 'accessJournalRecords' };
const searchOptions = {
  defaultRouteQuery: {
    pageNum: '1',
  },
  initialSearchDataStates: [
    'orderId', 'loginUserName', 'category', 'dates', 'isError',
  ],
  customTypes: {
    isError: 'booleanString',
    dates: 'array',
  },
};

const accessJournalRecordsHeaders = Object.freeze([
  { text: 'Order ID', value: 'orderId' },
  { text: 'Login', value: 'loginUserName' },
  { text: 'Category', value: 'category' },
  { text: 'Timestamp', value: 'timestamp' },
  { text: 'Error', value: 'isError' },
  { text: 'Arguments', value: 'arguments', width: '10px' },
]);

export default {
  name: 'IrAccessJournal',

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  data() {
    return {
      accessJournalRecords: [],
      accessJournalCategories: [],

      argumentsCode: '',
      argumentsCodeDialog: false,

      accessJournalRecordsHeaders,
    };
  },

  computed: {
    searchFieldsConfig() {
      return [
        {
          value: 'orderId', label: 'Order ID', type: 'text', flex: 'sm2',
        },
        {
          value: 'loginUserName', label: 'Login', type: 'text', flex: 'sm3',
        },
        {
          value: 'category',
          items: this.accessJournalCategories,
          label: 'Category',
          type: 'inputcombobox',
          flex: 'sm3',
        },
        {
          value: 'dates', label: 'Date Range', type: 'daterange', flex: 'sm3',
        },
        {
          value: 'isError', label: 'Error', type: 'toggle', flex: 'sm1',
        },
      ];
    },
  },

  created() {
    this.getAccessJournalCategories();
  },

  methods: {
    getAccessJournalRecords(params) {
      return this.wrapToLoadingFn({
        req: AccessJournalApiService.getAccessJournalRecords.bind({}, params),
        withLoadingSpinner: false,
        onSuccess: (r) => {
          this.accessJournalRecords = r;
        },
      });
    },

    getAccessJournalCategories() {
      this.wrapToLoadingFn({
        req: AccessJournalApiService.getAccessJournalCategories,
        withLoadingSpinner: false,
        onSuccess: (r) => {
          this.accessJournalCategories = r;
        },
      });
    },

    showArguments(argumentsCode) {
      this.argumentsCode = JSON.stringify(JSON.parse(argumentsCode), null, '\t');
      this.argumentsCodeDialog = true;
    },

    formatDisplayDate,
    wrapToLoadingFn,
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('vbt-search',{attrs:{"items":_vm.searchFieldsConfig},on:{"reset":_vm.onResetSearch,"search":_vm.onSearch},model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),_c('vbt-content-box',{attrs:{"loading":_vm.isLoading,"loading-error":_vm.loadingError,"no-content":_vm.noContent,"title":"Information"}},[_c('vbt-table',{attrs:{"headers":_vm.accessJournalRecordsHeaders,"items":_vm.accessJournalRecords},scopedSlots:_vm._u([{key:"orderId",fn:function(ref){
var orderId = ref.item.orderId;
return [_vm._v(" "+_vm._s(orderId || '-')+" ")]}},{key:"timestamp",fn:function(ref){
var timestamp = ref.item.timestamp;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(timestamp))+" ")]}},{key:"isError",fn:function(ref){
var isError = ref.item.isError;
return [_c('ir-state-icon',{attrs:{"state":isError}})]}},{key:"arguments",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!item.arguments,"icon":"","small":"","color":"info"},on:{"click":function($event){return _vm.showArguments(item.arguments)}}},on),[_c('v-icon',[_vm._v(" remove_red_eye ")])],1)]}}],null,true)},[_vm._v(" Show arguments ")])]}}])}),_c('vbt-pagination',{attrs:{"items-count":_vm.pagedItemsCount},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}}),_c('vbt-dialog',{attrs:{"title":"Arguments","hide-submit-btn":"","width":"900px"},model:{value:(_vm.argumentsCodeDialog),callback:function ($$v) {_vm.argumentsCodeDialog=$$v},expression:"argumentsCodeDialog"}},[_c('vbt-code',{attrs:{"value":_vm.argumentsCode,"readonly":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }